import { mapActions } from 'vuex';

const common = {
  methods: {
    ...mapActions([
      'showOverlay',
      'hideOverlay',
      'showInfo',
      'hideInfo',
      'hideHideInfo',
      'showDialog',
      'hideDialog',
      'showApp',
      'hideApp'
    ]),
    cmnAxiosPost(res) {
      // sign finished or not started
      if (res.data.status == 199) {
        this.showInfo({ title: 'INFO', text: res.data.message });
        return false;
      }
      // validation error
      if (res.data.status == 100) {
        this.validateForm(res.data.data);
        return false;
      }
      // validation error, prethodne forme
      if (res.data.status == 101) {
        let text = 'Greška u validaciji.<br>';
        text += 'Nije moguće potvrditi ovaj korak jer prethodni koraci nisu dobro upisani.<br>';
        text += 'Pregledajte i provjerite jeste li upisali ispravno sve prethodne korake u prijavi.<br>';
        this.showDialog({ text, app: true });
        return false;
      }
      // other errors
      else if (res.data.status > 0 && res.data.status < 100) {
        this.showDialog({ text: res.data.message, app: true });
        return false;
      }
      return true;
    },
    cmnAxiosError(error, showApp) {
      console.log(error);
      this.showDialog({ text: error, app: showApp });
    },
    formData() {
      var result = {};
      for (const f in this.$data.form) {
        result[f] = this.$data.form[f].value;
      }
      return result;
    },
    validateForm(res) {
      let count = 0;
      for (const f in this.$data.form) {
        if (f in res) {
          this.$data.form[f].error = res[f];
          count++;
        }
        else {
          this.$data.form[f].error = '';
        }
      }

      let text = 'Greška u validaciji.<br>';
      text += 'Pregledajte jeste li upisali dobro sva polja.<br>';
      text += 'Crvenom bojom je označeno polje koje nije ispravno upisano.';

      if (count) {
        this.showDialog({ text, app: true });
      }
    }
  }
}

export default common;