import VueRouter from 'vue-router';
import Home from './pages/Home.vue';

import Vue from 'vue';
import { publicPath } from '../vue.config'
Vue.use(VueRouter);

const home = {
  path: '/:hash',
  component: Home
};

const catchAll = {
  path: '*',
  component: Home
};

const routes = [
  home,
  catchAll
];

const router = new VueRouter({
  base: publicPath,
  routes,
  mode: 'history'
});

export default router;