<template>
 <div>
  <v-overlay
   :value="$store.getters.overlayVisible"
   light
  >
    <v-progress-circular
      width="5"
      size="55"
      indeterminate
    >
    </v-progress-circular>
  </v-overlay>

  <v-app>
    <the-header/>
    <v-main>
      <v-container v-if="$store.state.appVisible && !$store.state.infoVisible" class="mt-6">
        <v-flex>
          <router-view></router-view>
        </v-flex>
      </v-container>
      <the-info class="mt-16"
        v-if="$store.state.infoVisible"
        :title="$store.state.infoTitle"
        :text="$store.state.infoText"
        :button="$store.state.infoButton"
        @resetSign="resetSign"
      />
    </v-main>
  </v-app>

  <the-dialog
    :visible="$store.state.dialogVisible"
    @onCloseClick="hideDialog"
    :title="$store.state.dialogTitle"
    :text="$store.state.dialogText"
  />
</div>
</template>

<script>
import TheHeader from './components/TheHeader';
import TheDialog from './components/TheDialog';
import TheInfo from './components/TheInfo';
import common from './common.js';

export default {
  name: 'App',
  mixins: [ common ],
  created () {
    document.title = this.$appconfig.title;
    this.showApp();
  },

  methods: {
  },

  components: {
    TheHeader,
    TheDialog,
    TheInfo
  }
};
</script>

<style>
  .nbr-w50 { width: 50% }
  .nbr-mw800 { max-width: 800px; width: 100%; }
  .v-select__selection--comma, .v-list-item__title { white-space: break-spaces !important; }
  .v-list-item__title { max-width: 650px !important; }
</style>